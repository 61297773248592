import axios from 'axios'

import { useAuth } from '@/composables/auth.js'
import {
  trackAppInsightsError,
  trackAppInsightsFailedGatewayAuthorization as trackFailure,
} from '@/utils/application-insights'
import {
  getFailedRequestsCount,
  incrementFailedRequestsCount,
  resetFailedRequestsCount,
} from '@/utils/auth-state'

const FAILED_REQUESTS_RETRY_LIMIT = 3

const axiosHttp = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 30000,
})

axiosHttp.interceptors.request.use(
  async config => {
    const auth = useAuth()
    try {
      const token = await auth.getAccessToken()
      config.headers['Authorization'] = `Bearer ${token}`
    } catch (err) {
      console.error(err)
      if (err instanceof Error) {
        trackAppInsightsError(err)
      }
      incrementFailedRequestsCount()
      const failedRequests = getFailedRequestsCount()
      const shouldRetry = !didReachRetryLimit()

      trackFailure('tokenRetrieval', failedRequests, shouldRetry)

      if (shouldRetry) {
        console.error('Authorization failed')
        await auth.login()
      } else {
        console.error('Failed to authorize after multiple attempts')
      }
    }
    return config
  },
  error => Promise.reject(error)
)

axiosHttp.interceptors.response.use(
  // success
  response => {
    resetFailedRequestsCount()
    return response
  },
  // failure
  async error => {
    if (error.status === 401) {
      incrementFailedRequestsCount()
      const failedRequests = getFailedRequestsCount()
      const shouldRetry = !didReachRetryLimit()

      trackFailure('responseStatus', failedRequests, shouldRetry)

      if (shouldRetry) {
        console.error('Unauthorized, attempting login')
        await useAuth().login()
      } else {
        console.error('Failed to authorize after multiple attempts')
      }
    }
    return Promise.reject(error)
  }
)

export default axiosHttp

function didReachRetryLimit(): boolean {
  const failedRequests = getFailedRequestsCount()
  return failedRequests >= FAILED_REQUESTS_RETRY_LIMIT
}
